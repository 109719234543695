// imports
import React from "react";
import { useParams } from "react-router-dom";
import artistsData from "../../data/artists.json";

// styling
import "./Artist_Page.css";

// components
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Artist from "../../Components/Artist/Artist";

const ArtistPage = () => {
  const { id } = useParams();

  // Encontra o artista pelo nome formatado
  const artist = artistsData.find((artist) => artist.id === parseInt(id));

  if (!artist) {
    return <div>Artist not found</div>;
  }

  return (
    <>
      <Header />
      <Artist
        name={artist.name}
        imageUrl={artist.imageUrl}
        concertLocation={artist.concertLocation}
        concertTime={artist.concertTime}
        bio={artist.bio}
        socialMedia={artist.socialMedia}
      />
      <Footer />
    </>
  );
};

export default ArtistPage;
