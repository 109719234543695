// imports
import React from "react";
import { useParams } from "react-router-dom";
import artists from "../../data/artists.json";
import festivals from "../../data/festivals.json";
import partnerships from "../../data/partners.json" 

// styling
import "./Lineup_Page.css";

// components
import Lineup from "../../Components/Lineup/Lineup";
import LineupHeader from "../../Components/LineupHeader/LineupHeader";
import Partnerships from "../../Components/Partnerships/Partnerships";



const LineupPage = () => {

  const { editionId } = useParams();
  const festivalEdition = festivals.find(festival => festival.id === parseInt(editionId));

  const festivalName = festivalEdition.name;
  const festivalLineup = festivalEdition.lineupUrl;
  const festivalBackgroundImage = festivalEdition.backgroundImage;
  const date = festivalEdition.dates;

  if (!festivalEdition) {
    return <div>Edição não encontrada</div>;
  }

  const lineupArtists = artists.filter(artist => festivalEdition.artists_ids.includes(artist.id));



  return (
    <>
      <LineupHeader name={festivalName} lineup={festivalLineup} backgroundImage={festivalBackgroundImage} />
      <Lineup date={date} artists={lineupArtists} />
      <Partnerships editionId={parseInt(editionId)} partnershipsData={partnerships} />
    </>
  );
};

export default LineupPage;
