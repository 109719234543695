// dependencies
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";


// scenes
import Home from "./Screens/Home/Home";
import Festival from "./Screens/Festival/Festival";
import ArtistPage from "./Screens/Artist_Page/Artist_Page";
import LineupPage from "./Screens/Lineup_Page/Lineup_Page";
import ScrollToTop from "./Components/ScrolltoTop/ScrolltoTop";



function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="page-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/artist/:id" element={<ArtistPage />} />
          <Route path="/festival/:editionId" element={<LineupPage />} />
          <Route path="/festival" element={<Festival />} />

          {/* <Route path="/2022" element={<Edicao2022 />} />
            <Route path="/cartaz" element={<ArtistPage />} />     
            <Route path="/voluntariado" element={<Voluntariado />} />
            <Route path="/taskforce" element={<TaskForce />} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
