import React from "react";

import "./SocialMediaFEN.css";
import { FaInstagram } from 'react-icons/fa';

const SocialMediaFEN = () => {
    return (<div className="social-media-links">

        <a href="https://www.instagram.com/fen.festival" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
            <p className="icon-text">FEN FESTIVAL</p>
        </a>

        <a href="https://www.instagram.com/cidadefulgurante" target="_blank" rel="noopener noreferrer">
            <FaInstagram />
            <p className="icon-text">CIDADE Fulgurante</p>
        </a>

    </div>);
};

export default SocialMediaFEN;

