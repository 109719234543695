import React, { useState, useEffect } from "react";
import "./Partnerships.css";

const Partnerships = ({ editionId, partnershipsData }) => {
    const [, setIsMobile] = useState(window.innerWidth < 767);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 767);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Encontrar a edição com base no ID fornecido
    const edition = partnershipsData.find(ed => ed.id === editionId);
    console.log(edition)


    return (
        <div className="partner-footer">
            <div className="left-side">

            </div>
            <div className="right-side">

                {edition && edition.partnerships.length > 0 ? (
                    edition.partnerships.map((partner, index) => (
                        partner.name && partner.url ? (
                            <img
                                className="partner-logo"
                                src={partner.url}
                                alt={partner.name}
                            />
                        ) : null
                    ))
                ) : (
                    <p>Nenhum parceiro disponível para esta edição.</p>
                )}
            </div>
        </div>
    );
};

export default Partnerships;
