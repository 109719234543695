import React, { useEffect, useState } from "react";
import "./News.css";
import AnimatedButton from "../AnimatedButton/AnimatedButton";
import MobileButton from "../MobileButton/MobileButton";

const maxDisplay = 3;
const News = ({ newsMetadata }) => {
  const [windowWidth, setwindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setwindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderReadMoreButton = (news) => {
    if (windowWidth > 950) {
      return (
        <div className="news-item-content-button-desktop">
          <AnimatedButton text="Ler mais" link={news.link} />
        </div>
      );
    }
    else {
      return (
        <div className="news-item-content-button-mobile">
          <MobileButton text="Ler mais" link={news.link}/>
        </div>
      );
    }
  };

  if (!Array.isArray(newsMetadata) || newsMetadata.length === 0) {
    console.error("newsMetadata is not an array or it is empty");
    return null;
  }

  const displayedNews = newsMetadata.slice(0, maxDisplay);

  return (
    <div className="news-container">
      {displayedNews.map((news, index) => (
        <div key={index} className="news-item">
          <div
            className="news-item-bg"
            style={{ backgroundImage: `url(${news.imageUrl})` }}
          ></div>
          <div className="news-item-content">
            <div className="news-title">{news.title}</div>
            {renderReadMoreButton(news)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default News;
