// imports
import React from "react";

// styling
import "./Home.css";

// components
import LandingPage from "../../Components/LandingPage/LandingPage";
import News from "../../Components/News/News";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";

import newsMetadata from "../../data/news.json";

const Home = () => {
  return (
    <>
      <div className="header-landing-page-container">
        <Header />
        <LandingPage />
      </div>
      <News newsMetadata={newsMetadata} />
      <Footer />
    </>
  );
};

export default Home;
