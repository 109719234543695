import React from "react";
import { FaFacebookF, FaInstagram, FaSpotify } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const imageUrl = "https://i.imgur.com/UxtGPzl.png"; // Provisório

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="logo-container">
          <img className="fen-logo" src={imageUrl} alt="FEN Festival Logo" />
        </div>
        <div className="social-text">
          <div className="social-media">
            <FaFacebookF
              className="social-icon"
              onClick={() => openInNewTab("https://www.facebook.com/fenfestival2023")}
            />
            <FaInstagram
              className="social-icon"
              onClick={() => openInNewTab("https://www.instagram.com/fen.festival/")}
            />
            <FaSpotify
              className="social-icon"
              onClick={() => openInNewTab("https://open.spotify.com/user/31iclq2n5lfsfvsy2bsi7ywefgim")}
            />
          </div>
          <div className="footer-text">
            A MÚSICA INVADE A ALDEIA 2024 © FEN FESTIVAL
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
