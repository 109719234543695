// imports
import React from "react";
import { FaSpotify, FaInstagram } from 'react-icons/fa';
import "./Artist.css";

const Artist = ({ name, imageUrl, concertLocation, concertTime, bio, socialMedia }) => {
    return (
        <div className="artist-container">
            <div className="artist-image">
                <img src={imageUrl} alt={name} />
            </div>
            <div className="artist-info">
                <h1>{name}</h1>
                <h2>{concertLocation}</h2>
                <p className="concert-time">{concertTime}</p>
                <p>{bio}</p>
                <div className="artist-links">
                    {socialMedia.spotify && (
                        <a href={socialMedia.spotify} target="_blank" rel="noopener noreferrer">
                            <FaSpotify />
                        </a>
                    )}
                    {socialMedia.instagram && (
                        <a href={socialMedia.instagram} target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Artist;
