import React from "react";
import "./AnimatedButton.css";

const AnimatedButton = ({ text, link }) => {
  return (
    <div className="button-wrapper">
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="button-news"
      >
        <span className="button-circle">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">{text}</span>
      </a>
    </div>
  );
};

export default AnimatedButton;
