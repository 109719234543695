// imports
import React from "react";

// styling
import "./Festival.css";
import SocialMediaFEN from "../../Components/SocialMediaFEN/SocialMediaFEN";

// components
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Video from "../../Components/Video/Video";


const Festival = () => {
    return (
        <>
            <Header />
            <div className="festival-container">
                <h1 className="title">O FESTIVAL</h1>
                <Video url="https://www.youtube.com/embed/_JqIu1qRnDE?si=x7opl7cb-yNjR99W" />
                <div className="festival-description">
                    <p>A CidadeFulgurante-Associação nasce em Fafe com o objetivo de promover o bem-estar social e a cultura entra a comunidade fafense. Um dos seus projetos mais emblemáticos é o FEN Festival. </p>
                    <p>
                        O FEN é um evento que dinamiza arte e a música no norte de Portugal, reunindo gerações através da cultura.</p>
                    <p>Com origem em Várizea Cova, Fafe, o FEN Festival não é apenas uma iniciativa cultural de grande importância  capaz de promover a música e a cultura local, também uma forma de aproximar os jovens às origens e mostrar que arte pode ser concebida no mais remoto local.
                    </p>
                    <p>Depois das edições de 2022 e 2023 surge, em 2024, o FEN Winter Sessions. A primeira edição do evento surge da vontade de lutar contra a monotonia do Inverno, incorporando a cidade de Fafe com a arte. Distingue-se pela sua vertente itinerante, ambicionando mostrar que a cultura é parte aglutinadora de qualquer região. Um importante e necessário símbolo que concede às comunidades um liberdade e emancipação que só a posse de uma dimensão providenciar.</p>
                    <p> Combatendo a centralização cultural, o FEN Winter Sessions traz, a 23 de
                        novembro, muita música ao interior do Minho. Fafe recebe a primeira edição do
                        festival que se divide por entre algumas das salas emblemáticas da cidade.</p>
                </div>
                <SocialMediaFEN />

            </div>
            <Footer />
        </>
    );
};

export default Festival;
