/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css';
import edicoes from '../../data/festivals.json';

const Header = () => {
  const [submenuEdicoesVisible, setSubmenuEdicoesVisible] = useState(false);
  // const [submenuInformacoesVisible, setSubmenuInformacoesVisible] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const navigate = useNavigate();
  const sidebarRef = useRef();

  const handleWindowClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      const menuButton = document.querySelector('.menu-button');
      if (event.target !== menuButton) {
        setSidebarVisible(false);
        if (window.innerWidth <= 768) {
          setSubmenuEdicoesVisible(false);
          // setSubmenuInformacoesVisible(false);
        }
      }
    }
  };

  const handleWindowScroll = () => {
    setSidebarVisible(false);
  };

  const handleResize = () => {
    setSubmenuEdicoesVisible(window.innerWidth > 768);
    // setSubmenuInformacoesVisible(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener('click', handleWindowClick);
    window.addEventListener('scroll', handleWindowScroll);
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('click', handleWindowClick);
      window.removeEventListener('scroll', handleWindowScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
    setSidebarVisible(false);
    setSubmenuEdicoesVisible(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const handleEdicoesClick = () => {
    if (window.innerWidth <= 768) {
      // Mobile behavior: toggle submenu
      setSubmenuEdicoesVisible(!submenuEdicoesVisible);
    } else {
      // Desktop behavior: show submenu without toggle
      setSubmenuEdicoesVisible(true);
    }
  };

  const navClass = sidebarVisible ? 'open' : '';

  return (
    <header className="header">
      {/* <div className="logo-container">
        <a onClick={() => handleNavigate('/')} className="logo-link">
          <img src="https://i.imgur.com/UxtGPzl.png" alt="Logo do Festival" className="logo" />
        </a>
      </div> */}
      <button onClick={toggleSidebar} className="menu-button">☰</button>
      <nav ref={sidebarRef} className={`nav ${navClass}`}>
        <button onClick={() => setSidebarVisible(false)} className="close-button">X</button>
        <ul className="nav-list">
          <li className="nav-item">
            <button onClick={() => handleNavigate('/')} className="nav-link">
              HOME
            </button>
          </li>
          <li className="nav-item">
            <button onClick={() => handleNavigate('/festival')} className="nav-link">
              O FESTIVAL
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link nav-item-edicoes ${submenuEdicoesVisible ? 'submenu-visible' : ''}`}
              onClick={handleEdicoesClick}
            >
              <span>EDIÇÕES</span>
              {submenuEdicoesVisible && (
                <ul className="submenu">
                  {edicoes.map((edicao) => (
                    <li key={edicao.id} onClick={() => handleNavigate(`/festival/${edicao.id}`)}>
                      {edicao.id}
                    </li>
                  ))}
                </ul>
              )}
            </button>
          </li>
          {/* <li className="nav-item">
            <button
              className={`nav-link nav-item-informacoes ${submenuInformacoesVisible ? 'submenu-visible' : ''}`}
              onClick={() => setSubmenuInformacoesVisible(!submenuInformacoesVisible)}
            >
              <span>INFORMAÇÕES</span>

            </button>
          </li>
          */}
          <li className="nav-item">
          <a href="https://shotgun.live/pt-pt/events/fen-winter-sessions" target="_blank" rel="noopener noreferrer">
            <button className="nav-item-ticket">
              BILHETES
            </button>
            </a>
          </li> 
        </ul>
      </nav>
    </header>
  );
};

export default Header;
