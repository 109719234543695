import React from "react";
import PropTypes from "prop-types";
import "./Video.css";

const Video = ({ url }) => {
  return (
    <div className="video-container">
      <iframe
        width="900"
        height="500"
        src={url}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

Video.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Video;
