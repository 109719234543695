import React from "react";
import "./MobileButton.css";

const MobileButton = ({ text, link }) => {
  return (
    <div>
      <a target="_blank" rel="noopener noreferrer" className="mobile-button-wrapper" href={link}>{text}</a>
    </div>
  );
};

export default MobileButton;
